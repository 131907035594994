<script>
  import { createEventDispatcher } from "svelte";
  import * as timeago from "timeago.js";
  import Util from "../common/Util/Utility";
  const dispatch = createEventDispatcher();

  export let data;
  export let printCount;
</script>

<div class="relative mb-2">
  <div class="flex">
    <div
      class="flex-1 p-4 relative border border-gray-200 bg-white shadow-md rounded-tl-lg rounded-bl-lg cursor-pointer hover:bg-gray-100"
      on:click={() => dispatch("click")}
    >
      {#if data["requestToVoid"] && data["status"] != "VOIDED"}
        <div
          class="px-4 py-1 flex items-center rounded-lg bg-yellow-500 text-white mb-2 font-medium"
        >
          <i class="bx bx-error-circle mr-1" /> Requested Void
        </div>
      {/if}
      <div class="flex justify-between items-center">
        <h4 class="text-md font-medium text-gray-800">
          Transaction #{data["code"].substr(0, 8).toUpperCase()}
          {#if data["employeeId"] == "BJWMOBILE"}
            <span class="text-xs px-2 py-1 bg-blue-500 text-white rounded-md"
              >MOBILE</span
            >
          {/if}
        </h4>
        <span class="text-sm text-gray-500">
          {timeago.format(new Date(data["createdAtOnLocal"]))}</span
        >
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Order No: <span class="font-medium"
            >{data["orderNo"] ? data["orderNo"] : "-"}</span
          >
        </h4>
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Status: <span
            class="font-medium {data['status'] != 'SUCCESS'
              ? 'text-red-500'
              : 'text-green-500'}">{data["status"]}</span
          >
        </h4>
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Items ({data["items"].reduce(
            (sumAdd, itemAdd) => sumAdd + itemAdd.amount,
            0
          )})
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-user" />
        <h4 class="text-sm  text-gray-800">
          {data["customer"]["name"] ? data["customer"]["name"] : "-"}
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-phone" />
        <h4 class="text-sm  text-gray-800">
          {data["customer"]["phone"] ? data["customer"]["phone"] : "-"}
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-chair" />
        <h4 class="text-sm  text-gray-800">
          {data["customer"]["table"] ? data["customer"]["table"] : "-"}
        </h4>
      </div>
      <p
        class="absolute right-4 bottom-4 text-lg text-gray-800 font-medium m-0"
      >
        {Util.formatRupiah(data["total"], "Rp .")}
      </p>
    </div>
    <div
      class="bg-primary rounded-tr-lg rounded-br-lg flex items-center justify-center px-4 hover:opacity-90 cursor-pointer"
      on:click={() => dispatch("printTransaction")}
    >
      <i class="bx bx-printer text-2xl text-white" />
      <span class="text-white ml-1">{printCount}</span>
    </div>
  </div>
</div>
