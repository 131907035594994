<script>
   import Footer from '../../components/Footer.svelte';
</script>

<div class="relative min-h-screen">
  <slot/>
	<div class="absolute bottom-0 right-0 left-0">
		<Footer />
	</div>
</div>
