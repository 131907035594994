<div
    class="container relative w-full max-w-full mx-auto flex justify-center items-center py-24 px-6 bg-gray-50 h-screen"
>
    <div class="max-w-5xl w-full font-sans">
        <div class="flex justify-center items-center gap-4">
            <i class="bx bx-error text-danger h-full" style="font-size: 8rem;" />
            <div class="flex flex-col">
                <p class="text-4xl text-danger font-semibold">Invalid URL</p>
                <p class="text-2xl">Oops! The requested URL was not found</p>
                <p class="text-xl">
                    the reason for the problem may be a mistyped URL. Please ensure the URL is in the correct format.
                </p>
            </div>
        </div>
    </div>
</div>
