import "svelte-spa-router";
import { loggedInUserId } from "./store";
import { wrap } from "svelte-spa-router/wrap";
import Home from "./pages/Home.svelte";
import Login from "./pages/Login.svelte";
import Store from "./pages/Store.svelte";
import NotFound from "./pages/NotFound.svelte";
import Error from "./pages/Error.svelte";
let userId;
loggedInUserId.subscribe((val) => {
    userId = val;
});
const routes = {
    "/": wrap({
        component: Home,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId && localStorage.getItem("idStore") ? true : false;
            },
        ],
    }),
    "/store": wrap({
        component: Store,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/unauthorized": Error,
    "/login": Login,
    // The catch-all route must always be last
    "*": NotFound,
};
export default routes;
