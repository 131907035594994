<script>
  import Button from "../common/control/Button.svelte";
  import { connection, loggedInUserId, user } from "../store";
  import Cookies from "js-cookie";
  import Logo from "../common/Logo.svelte";
  import { onMount } from "svelte";
  import Router, { replace } from "svelte-spa-router";
  import Loading from "../common/Loading.svelte";

  import Layout from "../common/layout/LayoutAuth.svelte";

  let storeSelect;
  let storeSelected = "";
  let storeSelectedName = "";
  let isLoading;

  onMount(() => {
    isLoading.toggle();
    $connection
      .call(
        "stores.byManagerAndEmployees",
        $loggedInUserId,
        function (err, res) {
          console.log(err);
          console.log(res);
        }
      )
      .then((e) => {
        storeSelect = e.filter(
          (st) => st.type == "STORE" && st.status == "ACTIVE"
        );
        storeSelected = storeSelect[0]._id;
        storeSelectedName = storeSelect[0].name;
        isLoading.toggle();
      });
  });

  function handleEnter() {
    localStorage.setItem("idStore", storeSelected);
    localStorage.setItem("StoreName", storeSelectedName);
    replace("/");
  }
</script>

<Loading text="Loading..." bind:this={isLoading} />

<Layout>
  <div
    class="max-w-md w-full p-6 border border-gray-200 bg-white shadow-lg rounded-md font-sans"
  >
    <div class="relative flex flex-wrap">
      <div class="w-full relative">
        <div
          class="p-4 bg-white shadow-lg rounded-full flex items-center justify-center w-40 h-40 absolute -top-24"
          style="left: 50%; margin-left: -75px;"
        >
          <Logo />
        </div>
        <div class="flex space-x-4">
          <div class="flex-1" style="height: 400px;">
            <div class="text-center mt-20 mb-4 flex flex-col items-center">
              <p class="font-bold text-lg">Choose Store</p>
            </div>
            <div
              class="flex-1 space-y-2 flex-col items-center justify-center py-4 px-4 w-full block"
              style="height:260px; overflow-y: auto;"
            >
              {#if storeSelect != null && storeSelect.length > 0}
                {#each storeSelect as store}
                  {#if store._id == storeSelected}
                    <Button extClass="w-full" on:click={() => {}}
                      >{store.name}</Button
                    >
                  {:else}
                    <Button
                      extClass="w-full"
                      on:click={() => {
                        storeSelected = store._id;
                        storeSelectedName = store.name;
                      }}
                      status="light"
                      appearance="outline">{store.name}</Button
                    >
                  {/if}
                {/each}
              {:else}
                <p>No Store Found.</p>
              {/if}
            </div>
          </div>
        </div>

        <div class="w-100 mt-5">
          <Button size="large" extClass="w-full py-2" on:click={handleEnter}
            >Enter</Button
          >
        </div>
      </div>
    </div>
  </div>
</Layout>
