<script>
  // make dynamic date to be added to footer
  import { version, lastUpdate } from "../config";
  let date = new Date().getFullYear();
</script>

<footer class="h-full border-t">
  <div class="container mx-auto px-5 py-3">
    <!-- <hr class="mb-4 border-b-1 border-gray-300" /> -->
    <div
      class="flex flex-col flex-wrap items-start justify-start w-full text-gray-500 text-xs font-light"
    >
      <p class="font-normal pb-1">Version: {version}</p>
      <p class="font-normal pb-1">update: {lastUpdate}</p>
      <p class="font-bold">© Pesona Dari Timur</p>
      <!-- <p class="text-gray-400">
        We're provider of quality IT solutions and reliable service in software
        dev.
      </p> -->
    </div>
  </div>
</footer>
