<script lang="ts">
  import { addMinutes } from "date-fns";
  import { db } from "./config";
  import Cookies from "js-cookie";
  import goPrint from "./print";

  import Input from "./common/control/Input.svelte";

  import Button from "./common/control/Button.svelte";
  import routes from "./routes";
  import Router, { replace } from "svelte-spa-router";
  import Util from "./common/Util/Utility";

  import {
    connection,
    loggedInUserId,
    store,
    user,
    transactions as transactionLocal,
  } from "./store";
  import Logo from "./common/Logo.svelte";
  import hi from "date-fns/locale/hi";

  // export let name: string;

  let storeId = localStorage.getItem("idStore");
  let storeName = localStorage.getItem("StoreName");
  // const storeId = "iS2yzp5S5YJvY5kxJ"; //local

  let trans = [];
  let history = [];

  let trueUser;
  let storeSub;
  let storeSelect;
  let chooseStore = true;

  connection.subscribe((server) => {
    (async () => {
      await server.connect();
      // connection is ready here
      const token = Cookies.get("token");
      if (token !== null || token !== undefined) {
        await server.login({ resume: token });
        loggedInUserId.set(server.userId);
        // replace("/");
      }
    })();

    server.on("connected", () => {
      // do something
      console.log("connected");
      clearNotToday();
    });
    server.on("login", async (m) => {
      console.log("User logged in as", m);
      loggedInUserId.set(m.id);
      Cookies.set("token", m.token, { expires: 1 });
      await afterLogin(m);
    });
    server.on("logout", () => {
      localStorage.setItem("user", null);
      localStorage.setItem("token", null);
      localStorage.setItem("idStore", null);
      localStorage.setItem("StoreName", null);
      Cookies.remove("token");
      console.log("User logged out");
      window.location.reload();
    });
    server.on("loginSessionLost", (id) =>
      console.error(
        `User {id} lost connection to server, will auto resume by default with token`
      )
    );
    server.on("disconnected", () => {
      // for example show alert to user
      console.log("disconnected");
    });
    server.on("error", (e) => {
      // global errors from server
      console.error("error:", e);
    });
    server.on("loginResume", (m) => {
      console.log("User resumed (logged in by token)", m);
      afterLogin(m);
    });
    server.on("loginResumeFailed", (m) => {
      console.log(
        "Failed to resume authorization with token after reconnection ",
        m
      );
    });

    async function afterLogin(m) {
      console.log("after login");
      await $connection.call("role.isCurrentInRoles.forPOS").then((e) => {
        trueUser = e;
      });
      storeSub = server.subscribe("stores.byLoggedinUser", m.id);
      await storeSub.ready();

      let userCollection = server.collection("users").reactive().one();
      userCollection.onChange((data) => {
        user.set(data);
      });

      //   transactionLocal.set([]);
      clearNotToday();

      if (trueUser) {
        let idStoreSelected = localStorage.getItem("idStore");
        if (idStoreSelected != "null" && idStoreSelected != null) {
          replace("/");
        } else {
          replace("/store");
        }
      }
    }
  });

  function handleConditionFailed(event) {
    if (event.detail.userData.user == undefined) {
      replace("/login");
    }
  }

  function clearNotToday() {
    const start = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
    const end = new Date(new Date().setHours(23, 59, 59, 999)).getTime();
    const trans = $transactionLocal.filter((tr) => {
      const curr = new Date(tr.createdAtOnLocal).getTime();
      if (curr >= start && curr <= end) true;
      else false;
    });
    transactionLocal.set(trans);
  }
</script>

<main>
  <Router {routes} on:conditionsFailed={handleConditionFailed} />
</main>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
